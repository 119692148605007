import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom";


import Mainpage from './views/mainpage'


const App = () => (
   
        <Mainpage />
    
)

export default App
