import React from 'react'
import { useSelector } from "react-redux";
import logo from '../logo.svg';
import '../Main.css';
import { Scene } from './scene';

import IntroPage from './IntroPage';
import PointCamera from "./PointCamera";
import TapToBotle from "./TapToBotle";
import OutroPage from "./OutroPage";
import PinchToScale from "./PinchToScale";
import MainMenuRight from "../Menus/MainMenuRight";
import RightButton from "../Menus/RightButton";
import { withTranslation } from "react-i18next";
import SwipeComponent from '../Menus/SwipeComponent';

function Mainpage() {
    const { modelsAreLoaded, ARMode, turnOnLightState, modelAugie } = useSelector((state) => state);

    return (<>
        {/*<IntroPage />*/}
        {/*ARMode && !turnOnLightState? <PointCamera /> : null*/}
        {/*turnOnLightState && !ARMode && !modelsAreLoaded? <TapToBotle /> : null*/}
        {/*modelsAreLoaded ? <OutroPage /> : null*/}
        {<Scene />}
        {/*modelAugie ? <PinchToScale /> : null*/}
        {/*<MainMenuRight />*/}
        {/*<RightButton />*/}
        {/*<SwipeComponent/>*/}

    </>
    );
}

export default Mainpage;
